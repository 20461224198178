(function() {
  const DownloadManager = function() {
    let $compile = null;
    let LeaseEditorService = null;
    let $scope = null;
    let $window = null;
    let ProcessStatusService = null;
    let $rootScope = null;
    let ApiService = null;
    let DownloadService = null;

    this.DOWNLOAD_TEXT = null;
    this.DOWNLOAD_HTML = null;

    this.init = (
      _$scope,
      _$window,
      _ProcessStatusService,
      _$rootScope,
      _LeaseEditorService,
      _ApiService,
      _DownloadService,
    ) => {
      $scope = _$scope;
      $window = _$window;
      ProcessStatusService = _ProcessStatusService;
      $rootScope = _$rootScope;
      LeaseEditorService = _LeaseEditorService;
      ApiService = _ApiService;
      DownloadService = _DownloadService;
      $scope.$on("downloadSelection", downloadSelectionInternal);
      $scope.$on("downloadSubdocument", downloadSubdocumentInternal);
    };

    function deleteNodesBeforeMarker(node) {
      const startMarker = node.querySelector('start-marker');
      let anchor = startMarker;
      let parentNode = startMarker.parentNode;
    
      while (parentNode && parentNode !== node.parentNode) {
        let isFound = false;
        let children = Array.from(parentNode.childNodes);
    
        for (let i = 0; i < children.length && !isFound; i++) {
          if (children[i] !== anchor) {
            if (children[i].nodeType === Node.ELEMENT_NODE) {
              if (!children[i].classList.contains("selected-for-download")) {
                children[i].remove();
              }
            } else {
              children[i].remove();
            }
          } else {
            isFound = true;
          }
        }
    
        anchor = anchor.parentNode;
        parentNode = anchor.parentNode;
      }
    
      startMarker.remove();
    }
    
    function deleteNodesAfterMarker(node) {
      const endMarker = node.querySelector('end-marker');
      let anchor = endMarker;
      let parentNode = endMarker.parentNode;
    
      while (parentNode && parentNode !== node.parentNode) {
        let isFound = false;
        let children = Array.from(parentNode.childNodes);
    
        for (let i = parentNode.childNodes.length - 1; i >= 0 && !isFound; i--) {
          if (children[i] !== anchor) {
            if (children[i].nodeType === Node.ELEMENT_NODE) {
              if (!children[i].classList.contains("selected-for-download")) {
                children[i].remove();
              }
            } else {
              children[i].remove();
            }
          } else {
            isFound = true;
          }
        }
    
        anchor = anchor.parentNode;
        parentNode = anchor.parentNode;
      }
    
      endMarker.remove();
    }

    function deleteUnselectedNodes(node) {
      // TODO:
      // - Images (& attachments)
      // - Selection of one word in a list item captures the list item definition as well
      // - Partial tables
      const nodes = node.querySelectorAll('p, h1, h2, h3, h4, h5, h6, table, .lp-hide-element, br, lease-attachment, ul');

      for (let i = 0; i < nodes.length; i++) {    
        if (!nodes[i].classList.contains("selected-for-download") && !nodes[i].closest(".selected-for-download")) {
          nodes[i].remove();
        }
      }

      const outlines = node.querySelector("outlines");
      if (outlines) {
        outlines.remove();
      }

      const floater = node.querySelector(".top-right-floater");
      if (floater) {
        floater.remove();
      }

      const script = node.querySelector(".script");
      if (script) {
        script.remove();
      }
    }

    function convertHeadingsToParagraphs(node) {
      const headings = node.querySelectorAll(".convert-to-paragraph");

      headings.forEach(heading => {
        const p = document.createElement("p");
        p.style = heading.style;
        p.innerHTML = heading.innerHTML;
        heading.parentNode.replaceChild(p, heading);
      });
    }

    function handleNoParagraphs(node) {
      const p = node.querySelector(".convert-no-paragraph");

      if (p) {
        const span = document.createElement("span");
        span.innerHTML = p.innerHTML;
        p.parentElement.insertBefore(span, p);
        p.remove();
      }
    }

    function clearRangyMarkers() {
      const root = document.querySelector(".lease--original")
      const markers = Array.from(root.querySelectorAll(".rangySelectionBoundary"));
      
      markers.forEach(node => {
        node.remove();
      });
    }

    function markSelectionForDownload() {
      let sel = rangy.getSelection();
      let range = sel.getRangeAt(0);
      let anchorNode = null;
      let focusNode = null;
      let commonAncestorContainer = null;
    
      if (sel.isBackwards()) {
        if (sel.focusNode.nodeType === Node.TEXT_NODE) { 
          anchorNode = sel.focusNode;
        } else if (sel.focusNode.nodeType === Node.ELEMENT_NODE) {
          if (sel.focusOffset === 1) {
            anchorNode = sel.focusNode.childNodes[0];
          } else if (sel.focusOffset === sel.focusNode.childNodes.length) {
            anchorNode = sel.focusNode.childNodes[sel.focusNode.childNodes.length - 1];
          } else {
            anchorNode = sel.focusNode.childNodes[sel.focusOffset];
          }
        }

        if (sel.anchorNode.nodeType === Node.TEXT_NODE) { 
          focusNode = sel.anchorNode;
        } else if (sel.anchorNode.nodeType === Node.ELEMENT_NODE) {
          if (sel.anchorOffset === 1) {
            focusNode = sel.anchorNode.childNodes[0];
          } else if (sel.anchorOffset === sel.anchorNode.childNodes.length) {
            focusNode = sel.anchorNode.childNodes[sel.anchorNode.childNodes.length - 1];
          } else {
            focusNode = sel.anchorNode.childNodes[sel.anchorOffset];
          }
        }
      } else {
        if (sel.anchorNode.nodeType === Node.TEXT_NODE) { 
          anchorNode = sel.anchorNode;
        } else if (sel.anchorNode.nodeType === Node.ELEMENT_NODE) {
          if (sel.anchorOffset === 1) {
            anchorNode = sel.anchorNode.childNodes[0];
          } else if (sel.anchorOffset === sel.anchorNode.childNodes.length) {
            anchorNode = sel.anchorNode.childNodes[sel.anchorNode.childNodes.length - 1];
          } else {
            anchorNode = sel.anchorNode.childNodes[sel.anchorOffset];
          }
        }

        if (sel.focusNode.nodeType === Node.TEXT_NODE) { 
          focusNode = sel.focusNode;
        } else if (sel.focusNode.nodeType === Node.ELEMENT_NODE) {
          if (sel.focusOffset === 1) {
            focusNode = sel.focusNode.childNodes[0];
          } else if (sel.focusOffset === sel.focusNode.childNodes.length) {
            focusNode = sel.focusNode.childNodes[sel.focusNode.childNodes.length - 1];
          } else {
            focusNode = sel.focusNode.childNodes[sel.focusOffset];
          }
        }
      }

      if (anchorNode.nodeType === Node.TEXT_NODE) {
        if (!/[^\t\n\r ]/.test(anchorNode.textContent)) {
          if (anchorNode.nextSibling) {
            anchorNode = anchorNode.nextSibling;
          } else {
            anchorNode = anchorNode.parentNode;
          }
        }
      }

      if (focusNode.nodeType === Node.TEXT_NODE) {
        if (!/[^\t\n\r ]/.test(focusNode.textContent)) {
          if (focusNode.previousSibling) {
            focusNode = focusNode.previousSibling;
          } else {
            focusNode = focusNode.parentNode;
          }
        }
      }

      let startBlock = anchorNode.getTopAnchor();
      if (startBlock.tagName === "TR") {
        startBlock = startBlock.parentNode.closest("[pid], [table-id]");
      }
      startBlock.classList.add("selected-for-download--start");

      let endBlock = focusNode.getTopAnchor();
      if (endBlock.tagName === "TR") {
        endBlock = endBlock.parentNode.closest("[pid], [table-id]");
      }
      endBlock.classList.add("selected-for-download--end");

      rangy.saveSelection();
      sel = rangy.getSelection();
      range = sel.getRangeAt(0);
      commonAncestorContainer = range.commonAncestorContainer;

      const tempStartMarker = commonAncestorContainer.querySelector(".rangySelectionBoundary");
      const startMarker = document.createElement("start-marker");
      startMarker.innerText = window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE;
      tempStartMarker.parentNode.replaceChild(startMarker, tempStartMarker);

      if (!startBlock.contains(startMarker)) {
        startBlock.insertBefore(startMarker, startBlock.firstChild);
      }

      const tempEndMarker = commonAncestorContainer.querySelector(".rangySelectionBoundary");
      const endMarker = document.createElement("end-marker");
      endMarker.innerText = window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE;
      tempEndMarker.parentNode.replaceChild(endMarker, tempEndMarker);

      if (!endBlock.contains(endMarker)) {
        endBlock.insertBefore(endMarker, null);
      }

      const blocks = range.getNodes([Node.ELEMENT_NODE], function(node) {
        if (
          node.getAttribute("pid") != null || 
          node.getAttribute("table-id") != null || 
          node.tagName === "LEASE-ATTACHMENT"
        ) {
          node.classList.add("selected-for-download");
          return true;
        }

        return false;
      });
      
      if (blocks.indexOf(startBlock) == -1) {
        blocks.push(startBlock);
      }
      if (blocks.indexOf(endBlock) == -1) {
        blocks.push(endBlock);
      }
      
      blocks.forEach(block => {
        const blockData = block.getBlockData();
        const roundTrip = blockData.getRoundTripNode();
        const list = block.querySelector(".word-indent");
        const isFullySelected = blockData.isFullySelected();
        
        if (roundTrip) {
          if (isFullySelected) {
            roundTrip.classList.add("selected-for-download");
          } else {
            if (block.tagName !== "P") {
              block.classList.add("convert-to-paragraph");
            }
          }
        }

        if (list) {
          if (isFullySelected) {
            list.classList.add("selected-for-download");
          }
        }

        if (!isFullySelected) {
          block.classList.add("convert-no-paragraph");
        }
        
        block.classList.add("selected-for-download");
      });

      sel.setSingleRange(range);
    }

    function preSelectionForDownload(leaseSrc) {     
      if (window.isDebug) {
        versionControlManager.enterVCView();

        const displayElement = document.createElement("div");
        displayElement.id = "cloned-document";
        displayElement.appendChild(leaseSrc);

        document
          .querySelector(".lease.lease--modified")
          .appendChild(displayElement);

        const styleElement = document.createElement("style");
        styleElement.id = "cloned-document-style";
        styleElement.innerHTML = ".selected-for-download {border: solid 1px red; }";

        document.body.appendChild(styleElement);
      }
      
      const startBlock = leaseSrc.querySelector(".selected-for-download--start");
      const startBlockData = startBlock.getBlockData();
      const endBlock = leaseSrc.querySelector(".selected-for-download--end");
      const endBlockData = endBlock.getBlockData();

      deleteNodesBeforeMarker(startBlock);
      deleteNodesAfterMarker(endBlock);
      deleteUnselectedNodes(leaseSrc);
      convertHeadingsToParagraphs(leaseSrc);
      
      if (startBlockData.containingNode === endBlockData.containingNode) {
        handleNoParagraphs(leaseSrc);
      }

      if (window.isDebug) {
        versionControlManager.exitVCView();
        document.querySelector("#cloned-document").remove();
        document.querySelector("#selected-cloned-document").remove();
      }
    }

    function postSelectionForDownload() {
      const selectedForDownload = document.querySelectorAll(".selected-for-download");
      selectedForDownload.forEach(element => {
        element.classList.remove(
          "selected-for-download", 
          "selected-for-download--start", 
          "selected-for-download--end",
          "convert-to-paragraph",
          "convert-no-paragraph"
        );
      });

      const startMarker = document.querySelector("start-marker");
      startMarker.remove();
      
      const endMarker = document.querySelector("end-marker");
      endMarker.remove();
    }

    function downloadSubdocumentInternal(event, data) {
      ApiService.htmlToWord(data.file, window.ASSETS_DESIRED_STYLES)
        .then(function successCallback(fileResponse) {
          $scope.safeApply(() => {
            $scope.processing = false;
            ProcessStatusService.end('download');
          });

          const fileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          const fileName = `${LeaseEditorService.getFileName(data.options.filename)}`;
          DownloadService.download(fileResponse.data, fileType, fileName);
        },
        function errorCallback(response) {
          $scope.safeApply(() => {
            $scope.processing = false;
            ProcessStatusService.end('download');
          });
          console.log(response);
        },
      );
    }

    function downloadSelectionInternal(event, data) {
      ApiService.htmlToClausebook(data.file, window.ASSETS_DESIRED_STYLES).then(
        function successCallback(response) {
          if (response && response.status == 200) {
            $scope.$broadcast("downloadSelectionResult", {
              data: response.data
            });
          }
        },
        function errorCallback(response) {
          $scope.$broadcast("downloadSelectionResult", { data: null });
        }
      );
    }

    this.downloadSelection = async () => {
      const sel = window.getSelection();

      if (sel.type != "Range") {
        $scope.$broadcast("downloadSelectionResult", { data: null });
        return null;
      }

      clearRangyMarkers();
      markSelectionForDownload();

      this.prepareDownload("downloadSelection");
    };
    
    function getElementsBetween(startNode, endNode) {
      if (startNode.parentNode !== endNode.parentNode) {
        throw new Error("The provided nodes are not siblings.");
      }
    
      let currentNode = startNode.nextElementSibling;
      const doc = document.createElement("div");
      doc.classList.add("document");
      const root = document.createElement("div");
      root.classList.add("lease", "lease--original")
      root.appendChild(doc);
      const body = document.createElement("body");
      body.appendChild(root);
      const html = document.createElement("html");
      html.appendChild(body);
    
      while (currentNode && currentNode !== endNode) {
        const nextNode = currentNode.nextElementSibling;
        doc.appendChild(currentNode.cloneNode(true));
        currentNode = nextNode;
      }
    
      return html;
    }
    
    this.downloadSubdocument = async (subdocumentName, filename) => {
      this.prepareDownload("downloadSubdocument", null, {
        subdocumentName,
        filename,
      });
    };

    this.prepareDownload = async (eventName, broadcastScope, options) => {
      await this.updateImgSrc();

      if (eventName == "diffgramOriginal" && versionControlManager) {
        window.downloadForVersionControl = true;
        cloneAndDownloadDocument(eventName, broadcastScope);
      } else {
        if ($scope && $scope.download) {
          this.prepareDownloadInternal(null, eventName, null, options);
        } else {
          const result = cloneAndDownloadDocument(eventName, broadcastScope, options);
          return result;
        }
      }
    };

    this.toDataURL = async url => {
      const returnValue = new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
      return await returnValue;
    };

    this.updateImgSrc = async () => {
      const imgArr = $(document)
        .find(".lease")
        .not(".lease--modified")
        .find("img")
        .toArray();

      for (let index = 0; index < imgArr.length; index++) {
        const img = imgArr[index];
        if (img && img.src.indexOf("data:") === -1) {
          img.src = await this.toDataURL(img.src);
        }
      }
    };

    const cloneAndDownloadDocument = (eventName, broadcastScope, options) => {
      const doc = document.createElement("document");
      const body = document.createElement("body");
      const head = document.querySelector("head").cloneNode(true);
      const lease = document.createElement("div");
      const leaseSrc = document.querySelector(".lease--editor").cloneNode(true);
      lease.appendChild(leaseSrc);
      lease.classList.add("lease");

      leaseSrc.classList.remove("lease--editor");
      leaseSrc.classList.add("lease--download");

      const testVersion = doc.querySelector(".test-version-indicator");
      if (testVersion) {
        testVersion.remove();
      }

      if (eventName === "downloadSelection" || eventName === "downloadSelectionHtml") {
        try {
          preSelectionForDownload(leaseSrc);
        } catch (ex) {
          console.error(ex);
        } finally {
          postSelectionForDownload();
        }
      }

      doc.appendChild(head);
      body.appendChild(leaseSrc);
      doc.appendChild(body);

      const downloadStyle = document
        .getElementById("downloadStyle");

      if (downloadStyle) {
        downloadStyle.setAttribute("media", "all");
        head.appendChild(downloadStyle.cloneNode(true));
      }
        
      // fix page break
      doc.querySelectorAll('br[style*="relative"]').forEach(el => {
        el.setAttribute("style", "page-break-before:always; clear: both;");
      });

      if (window.isDebug) {
        console.log(doc);
      }

      this.DOWNLOAD_HTML = leaseSrc.innerHTML;
      this.DOWNLOAD_TEXT = leaseSrc.innerText;

      const html = this.prepareDownloadInternal($(doc), eventName, broadcastScope, options);

      return html;
    };

    const keepElementsForDownloadSelection = [
      "OUTLINE",
      "OUTLINE-END",
      "LEASE",
      "LEASE-END",
      "SECTION",
      "OUTLINES"
    ];

    this.removeAllNotSelectedElements = element => {
      if (
        !element.querySelector(".for-download") 
        && !element.closest(".for-download")
      ) {
        if (keepElementsForDownloadSelection.indexOf(element.tagName) == -1) {
          if (
            element.previousElementSibling &&
            element.previousElementSibling.tagName !== "DIV" && 
            !element.previousElementSibling.classList.contains('for-download') &&
            element.previousElementSibling.textContent.indexOf(
              "!!SET_LEVEL"
            ) !== -1
          ) {
            element.previousElementSibling.remove();
          }
          element.remove();
        }
      } else {
        Array.from(element.children).forEach(c => {
          this.removeAllNotSelectedElements(c);
        });
      }
    };

    this.getSelectionHtml = async () => {
      clearRangyMarkers();
      markSelectionForDownload();
      
      const result = this.prepareDownload("downloadSelectionHtml");
      return result;
    };

    this.getHtml = (rootElement, eventName, options) => {
      const isNewVersionControl =
        versionControlManager &&
        versionControlManager.eventName &&
        versionControlManager.eventName == eventName;

      let phaseOneVersionControl = false;

      if (window.downloadForVersionControl && versionControlManager) {
        window.downloadForVersionControl = false;
        phaseOneVersionControl = true;
      }

      // remove hidden elements (new-lease-if)
      let elementsToRemove = rootElement[0].querySelectorAll(
        ".lp-hide-element, .remove-on-download, .deleted-container, .deleted-page-break, .deleted-element"
      );
      for (
        let removeIndex = 0;
        removeIndex < elementsToRemove.length;
        removeIndex++
      ) {
        elementsToRemove[removeIndex].remove();
      }

      //remove white spaces between elements
      const hiddenDivList = rootElement[0].querySelectorAll("div.lp-hide");
      hiddenDivList.forEach(div => {
        div.childNodes.forEach(el => {
          if (el.nodeType === 3) {
            el.remove();
          }
        });
      });

      // Copy the style from container to generate block paragraphs
      const generateBlocks = rootElement[0].querySelectorAll("div[style] > p:not(.lp-hide)");
      generateBlocks.forEach(block => {
        const container = block.closest("div[style]");
        if (container) {
          const containerStyle = container.getAttribute("style");
          const blockStyle = block.getAttribute("style");
          block.setAttribute("style", `${containerStyle}; ${blockStyle}`);
        }
      });

      // Remove paragraphs which only consists of `hard-return` elements
      const hardReturns = rootElement[0].querySelectorAll("hard-return");
      hardReturns.forEach(item => {
        if (item.parentElement.children.length === 1) {
          item.parentElement.remove();
        }
      });

      if ($window.frameElement) {
        if (window.isTestEnv) {
          // save the html string on the DOM for tests purpose
          $window.frameElement.setAttribute("event-status", "finished");
          $window.frameElement.setAttribute(
            "data-file",
            rootElement.find(".lease").not(".lease--modified")[0].innerHTML
          );
          $window.frameElement.setAttribute(
            "data-text-file",
            rootElement.find(".lease").not(".lease--modified")[0].innerText
          );
        }

        if (
          !phaseOneVersionControl &&
          $window.frameElement.getAttribute("isDiff") === "true"
        ) {
          rootElement.find(".new-paragraph").each(function() {
            const element = this;
            const span = document.createElement("span");
            const nodes = Array.from(element.childNodes);

            element.insertBefore(span, element.childNodes[0]);
            nodes.forEach(node => {
              if (
                !(
                  node.nodeType === Node.ELEMENT_NODE &&
                  node.nodeName === "HARD-RETURN"
                )
              ) {
                node.remove();
                span.appendChild(node);
              }
            });
          });

          rootElement.find("[section-id]").each(function() {
            const element = $(this);

            let sectionId = element.attr("section-id");
            sectionId = window.getCalculatedSectionId(element, sectionId);

            const spans = $(this)
              .get(0)
              .querySelectorAll("span");
            spans.forEach(span => {
              const container = LeaseEditorService.findClosestContainer(
                span
              ).get(0);
              const nestedParagraphIndex = container.getAttribute(
                "data-nested-paragraph-index"
              );
              let fontFamily;

              if (nestedParagraphIndex !== null) {
                fontFamily =
                  "'section-id=" +
                  sectionId +
                  "!!nested=" +
                  nestedParagraphIndex +
                  "!!'";
              } else {
                fontFamily = "'section-id=" + sectionId + "!!'";
              }

              $(span).css("font-family", fontFamily);
            });
          });

          rootElement.find("[table-id]").each(function() {
            const tableId = $(this).attr("table-id");
            $(`<p>!!table-id=${tableId}!!</p>`).insertBefore(this);
          });
        }
      }

      if (isNewVersionControl || phaseOneVersionControl) {
        rootElement[0].querySelectorAll("[data-vc-id]").forEach(span => {
          const vcID = span.getAttribute("data-vc-id");
          let fontFamily = null; //`'section-id=${vcID}!!'`;

          const nestedContainer = span.closest(
            "[data-nested-paragraph-index]"
          );

          const nestedIndex = nestedContainer
            ? nestedContainer.getAttribute("data-nested-paragraph-index")
            : null;

          if (nestedIndex !== null) {
            fontFamily = `'section-id=${vcID}!!nested=${nestedIndex}!!`;
            // "'section-id=" +
            // sectionId +
            // "!!nested=" +
            // nestedParagraphIndex +
            // "!!'";
          } else {
            fontFamily = `'section-id=${vcID}!!'`;
          }

          // if(nestedIndex){
          //   fontFamily = fontFamily + `!!${nestedIndex}!!`;
          // }

          $(span).css("font-family", fontFamily);
        });

        rootElement.find("[table-id]").each(function() {
          const tableId = $(this).attr("table-id");
          $(`<p>!!table-id=${tableId}!!</p>`).insertBefore(this);
        });
      }

      // The list-item text is mostly ignored when downloading to word
      // but there are cases where the text of the list-item appears
      if (eventName !== "downloadSelectionHtml") {
        rootElement[0].querySelectorAll("span[list]").forEach(e => {
          const listData = e.getBlockData();
          if (!listData.static) {
            e.textContent = " ";
          }
        });
      }

      if (eventName === "downloadSelectionHtml") {
        rootElement[0].querySelectorAll("p").forEach(element => {
          if (
            element.textContent.trim().startsWith("!!") && 
            element.textContent.trim().endsWith("!!")
          ) {
            element.remove();
          }
        });
      }

      const leaseOriginal = rootElement
        .get(0)
        .querySelector(".lease--original");

      if (leaseOriginal) {
        leaseOriginal.removeWhitespace();
      }

      if (eventName !== "downloadSelectionHtml") {
        // Add a hint to the service about which tables are to be kept together
        rootElement[0]
          .querySelectorAll("generate-table[keep-together]")
          .forEach(tbl => {
            const p = document.createElement("p");
            p.textContent = "!!KEEP-TABLE-TOGETHER!!";
            tbl.insertBefore(p, tbl.firstChild);
          });
      }

      // Fill empty `new-paragraph`s with zero-width non-breaking space so they'll appear in the
      // downloaded version of the document
      rootElement
        .find(".new-paragraph, .inserted-list-item")
        .each(function() {
          const element = $(this);
          const text = element.text();
          const trim = text.trim();
          const isImage = element.hasClass("image-container") || element.find("img").length > 0;
          const isOutline = element.get(0).tagName === "OUTLINE";
          const isBreak = element.get(0).tagName === "BR";
          const isTR = element.get(0).tagName === "TR";

          const rangySelectionBoundaries = Array.from(
            element.get(0).querySelectorAll(".rangySelectionBoundary")
          );
          rangySelectionBoundaries.forEach(node => {
            node.remove();
          });

          if (element && element[0]) {
            element[0]
              .querySelectorAll("[style*=underline]")
              .forEach(node => {
                const nodeText = node.innerText;
                if (nodeText === "﻿" || nodeText === "​") {
                  node.style.textDecoration = "none";
                }

                if (node.style.textDecorationLine === "underline") {
                  node.style.textDecoration = "underline";
                }

                // An edge case where there's a underlined text under a parent with no 
                // text decoration (probably due to paste from Word), then the underlined text disappears
                const parent = node.parentElement;
                if (parent.style.textDecoration.indexOf("none") !== -1) {
                  parent.style.textDecoration = null;
                }
              });
          }

          if (trim.length === 0  && !isImage && !isOutline && !isBreak && !isTR) {
            if (element.find("br").length === 0) {
              element.html("&nbsp;");
            }
          }

          if (/^(?:﻿|​)+$/.test(text) && !isImage && !isOutline && !isBreak && !isTR) {
            if (element.find("br").length === 0) {
              element.html("&nbsp;");
            }
          }

          if (element.hasClass("new-paragraph--first")) {
            const originalMarginTop = element.attr(
              "data-original-margin-top"
            );
            element.css("marginTop", originalMarginTop);
          }
        });

      rootElement
        .find("[pid]")
        .each(function() {
          const element = $(this);
          const text = element.text();
          const trim = text.trim();
          const isImage = element.hasClass("image-container") || element.find("img").length > 0;
          const isOutline = element.get(0).tagName === "OUTLINE";
          const isBreak = element.get(0).tagName === "BR";
          const isTR = element.get(0).tagName === "TR";

          if (trim.length === 0  && !isImage && !isOutline && !isBreak && !isTR) {
            if (element.find("br").length === 0) {
              element.html("&nbsp;");
            }
          }
          
          if (/^(?:﻿|​)+$/.test(text) && !isImage && !isOutline && !isBreak && !isTR) {
            if (element.find("br").length === 0) {
              element.html("&nbsp;");
            }
          }
        });

      rootElement.find("[data-custom-style]").each(function() {
        const element = $(this);
        const customStyle = element.attr("data-custom-style");
        const style = $(this).attr("style");
        element.attr("style", style + " " + customStyle);
      });

      rootElement.find("[style*='lp, ']").each(function() {
        const element = $(this);
        const style = $(this).attr("style").replace("lp, ", "");
        element.attr("style", style);
      });

      let crrElements = rootElement[0].querySelectorAll("crr a");
      for (let index = 0; index < crrElements.length; ++index) {
        let a = crrElements[index];

        if (LeaseEditorService.isEmpty(a)) {
          a.parentElement.remove();
        } else {
          // remove a and keep text if needed
          if (
            window.user &&
            window.user.company &&
            window.user.company.companyProfile &&
            window.user.company.companyProfile
              .removeCrossReferenceLinksUponDownload
          ) {
            a.childNodes.forEach(child => {
              child.remove();
              a.parentElement.insertBefore(child, a);
            });
            a.remove();
          } else {
            a.style.textDecoration = "none";
            a.style.color = "black";
          }
        }
      }

      rootElement[0].querySelectorAll("hard-return").forEach(node => {
        if (
          node.previousSibling &&
          node.previousSibling.nodeType === Node.TEXT_NODE &&
          (node.previousSibling.textContent === "﻿" || node.previousSibling.textContent === "​") // ZERO WIDTH SPACE
        ) {
          node.previousSibling.textContent = "\xa0"; // NON-BREAKING SPACE
        }
      });

      if (eventName !== "downloadSelectionHtml") {
        try {
          rootElement[0]
            .querySelectorAll("[free-text] .word-numbers[style*='font: 7pt']")
            .forEach(node => {
              if (node.textContent.trim() === "") {
                node.innerHTML = "&nbsp;";
                if (node.getAttribute("style").indexOf("width") === -1) {
                  node.style.width = "1pt";
                }
              }
            });
        } catch (e) {}
      }

      if (eventName === "downloadSubdocument") {
        const startNode = rootElement[0].querySelector(`subdocument[name='${options.subdocumentName}']`);
        const endNode = rootElement[0].querySelector(`subdocument-end[name='${options.subdocumentName}']`);
        rootElement = getElementsBetween(startNode, endNode);
        rootElement = $(rootElement);
      }

      var html = rootElement.find("body").html();
      html = html
        .replace(/\u200B/g, "") // ZERO WIDTH SPACE
        .replace(/\u200D/g, "") // ZERO WIDTH JOINER
        .replace(/\ufeff/g, ""); // ZERO WIDTH NO-BREAK SPACE

      html = html.replace(/{{.*?}}/g, '');

      html = sanitizeHtml(html, {
        allowedTags: [
          "a",
          "b",
          "strong",
          "i",
          "u",
          "p",
          "span",
          // We want to keep `div`s when downloading to Word but not when copying to clipboard
          (eventName !== "downloadSelection" && eventName !== "downloadSelectionHtml") ? "div" : null,
          "br",
          "input",
          "img",
          "font",
          "table",
          "thead",
          "tbody",
          "tfoot",
          "colgroup",
          "col",
          "tr",
          "th",
          "td",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "crr",
          "crb",
          "sup",
          "sub",
          "ol",
          "ul",
          "li",
          "reset-lists",
          "outline",
          "two-columns",
          "two-columns-end"
        ],
        allowedAttributes: {
          a: ["id", "href", "name", "target"],
          img: ["src", "width", "height"],
          table: ["cellspacing", "cellpadding", "border"],
          td: ["colspan", "rowspan"],
          ol: ["type"],
          outline: ["name"],
          input: ["type", "value"],
          "*": ["style"]
        },
        allowedSchemes: ["http", "https"],
        allowedSchemesByTag: {
          img: ["data", "http", "https"]
        }
      });

      html = html.trim();

      if (window.isDebug) {
        console.log(html);
      }

      return html;
    }

    this.prepareDownloadInternal = (rootElement, eventName, broadcastScope, options) => {
      const that = this;
      
      let noFrame = false;
      if (!rootElement) {
        rootElement = $(document);
      } else {
        noFrame = true;
      }

      const html = that.getHtml(rootElement, eventName, options);

      setTimeout(function() {

        var file = new Blob([html], {
          type: "text/html"
        });

        if (!noFrame) {
          $scope.broadcastCustomEvent(file);
        } else {
          if (broadcastScope) {
            broadcastScope.$broadcast(eventName, { file, options });
          } else {
            $scope.$broadcast(eventName, { file, options });
          }
        }
      });

      return html;
    };
  };
  window.downloadManager = new DownloadManager();
})();
